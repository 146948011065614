import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      // invoicePlatformApiShopeeAuthUat
      component: () => import('@/views/shopeeApi/invoicePlatformApiShopeeAuth.vue'),
      meta: {
        pageTitle: '蝦皮API中心',
        breadcrumb: [
          {
            text: '登入作業',
            active: true,
          },
        ],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: '進銷項',
        breadcrumb: [
          {
            text: '進項消項交易',
            active: true,
          },
        ],
      },
    },
    {
      path: '/data-page',
      name: 'data-page',
      component: () => import('@/views/DataPage.vue'),
      meta: {
        pageTitle: '交易資料',
        breadcrumb: [
          {
            text: '交易資料',
            active: true,
          },
        ],
      },
    },
    {
      path: '/recepayable',
      name: 'recepayable',
      component: () => import('@/views/RecePayable.vue'),
      meta: {
        pageTitle: '應收付作業',
        breadcrumb: [
          {
            text: '應收付作業',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoicePlatformApiShopeeAuth',
      name: 'invoicePlatformApiShopeeAuth',
      component: () => import('@/views/shopeeApi/invoicePlatformApiShopeeAuth.vue'),
      meta: {layout: 'full',},
    },
    {
      path: '/invoicePlatformApiShopeeAuthUat',
      name: 'invoicePlatformApiShopeeAuthUat',
      component: () => import('@/views/shopeeApi/invoicePlatformApiShopeeAuthUat.vue'),
      meta: {layout: 'full',},
    },
    {
      path: '/shopee',
      name: 'shopee',
      component: () => import('@/views/Shopee.vue'),
      meta: {
        // pageTitle: '蝦皮賣家作業',
        // breadcrumb: [
        //   {
        //     text: '蝦皮賣家作業',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/shopeeReturn',
      name: 'shopeeReturn',
      component: () => import('@/views/ShopeeReturn.vue'),
      meta: { },
    },
    {
      path: '/ruten',
      name: 'ruten',
      component: () => import('@/views/Ruten.vue'),
      meta: { },
    },
    {
      path: '/shopline',
      name: 'shopline',
      component: () => import('@/views/Shopline.vue'),
      meta: { },
    },
    {
      path: '/kimo',
      name: 'kimo',
      component: () => import('@/views/Kimo.vue'),
      meta: { },
    },
    {
      path: '/fbbuy',
      name: 'fbbuy',
      component: () => import('@/views/Fbbuy.vue'),
      meta: { },
    },
    {
      path: '/saraashop',
      name: 'saraashop',
      component: () => import('@/views/Saraashop.vue'),
      meta: { },
    },
    {
      path: '/shopstore',
      name: 'shopstore',
      component: () => import('@/views/Shopstore.vue'),
      meta: { },
    },
    {
      path: '/easystore',
      name: 'easystore',
      component: () => import('@/views/Easystore.vue'),
      meta: { },
    },
    {
      path: '/myship',
      name: 'myship',
      component: () => import('@/views/Myship.vue'),
      meta: { },
    },
    {
      path: '/supersale',
      name: 'supersale',
      component: () => import('@/views/SuperSale.vue'),
      meta: { },
    },
    {
      path: '/fishgroup',
      name: 'fishgroup',
      component: () => import('@/views/Fishgroup.vue'),
      meta: { },
    },
    {
      path: '/iopen',
      name: 'iopen',
      component: () => import('@/views/Iopen.vue'),
      meta: { },
    },
    {
      path: '/cyberbiz',
      name: 'cyberbiz',
      component: () => import('@/views/Cyberbiz.vue'),
      meta: { },
    },
    {
      path: '/humile',
      name: 'humile',
      component: () => import('@/views/Humile.vue'),
      meta: { },
    },
    {
      path: '/setting-page',
      name: 'setting-page',
      component: () => import('@/views/SettingPage.vue'),
      meta: {
        pageTitle: '基本資料設定',
        breadcrumb: [
          {
            text: '基本資料設定',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
