
export default {
  namespaced: true,
  state: {
    user: {
      username: '',
      role:'',
      accountId: '',
    },
  },
  getters: {},
  mutations: {
    updateUser(state, user){
      state.user = user
    },
  },
  actions: {},
}
